import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../services/utils.service';
import { SessionService } from '../services/session.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare let $;
@Component({
  selector: 'app-registros',
  templateUrl: './registros.component.html',

})
export class RegistrosComponent implements OnInit {
	private clientSelect=0;
	private url="https://live.connect.cheil.com.co/api/";
	private localUrl="http://127.0.0.1:8000/api/";
	private isCampaign=false;
	private campaigns:any[]=[];
	private campaignsData;
	private campaignSelect;
	private campaignResponse;
	private  startDate= "0000-00-00";
	private  endDate= "0000-00-00";
	private  isEndDate=false;
	private  minDate: string = "0000-00-00";
	private  maxDate: string = "0000-00-00";
	public date = new Date();
	private clients = [{"id":1, "name":"Online Bussines"},{"id":2, "name":"Corporativo"},{"id":3, "name":"CRM"}];
	private clientName;
	private campaignName;
	private month;
	private day;
	private year; 
	private dataContact;
	private dataInteraction;
	private isData=false;
	private selectCampaigns= false;
	 private isClient=false;
	 private isStartDate=false;
	 private isfinishDate=false;
	 private table;
	 private isCampaignError= false;
	constructor( 
		private http: HttpClient,
		private session: SessionService,
		private utils: UtilsService) { 
		$('#main-form').fadeOut();
	}

	ngOnInit() {
		this.maxDate=this.dateNow();
		console.log('fecha actual'+this.maxDate);
		setTimeout(function() {
				$('#main-form').fadeOut();
			}, 50);
	}

	dateNow() {
		return this.dateNew('',true);
	}
	dateChange(){
		this.minDate=this.dateNew(this.startDate,false);
		this.endDate=this.dateNow();
		this.isEndDate=true;
	}
	checkCampaign(){
		console.log(this.selectCampaigns);
		if(this.selectCampaigns === true){
			let i=0;
			this.campaigns.forEach(camapign => {
				this.campaigns[i].value=true;
				i++;	
			});
		}else{
			let i=0;
			this.campaigns.forEach(camapign => {
				this.campaigns[i].value=false;
				i++;	
			});
		}
		console.log(this.campaigns);
	}
	verificarcampañas(){
		let i=0;
		
		this.campaigns.forEach(element => {
			console.log("0k");
		});
		
		return true;

	}

	dateNew(dateNew:string, newDate:boolean){
	
		if(newDate===true){
			this.date= new Date();
			this.month =""+ (this.date.getMonth() + 1);
			this.day =""+ this.date.getDate();
			this.year =""+ this.date.getFullYear();

			
		}else{
			this.date= new Date(dateNew);
			console.log("click: "+ this.date)
			this.month = (this.date.getDate() + 1) >= this.maxDaysOnMonth(this.date.getMonth()+1 , this.date.getFullYear()) ? '' + (this.date.getMonth() + 2):'' + (this.date.getMonth() + 1);
			this.day =(this.date.getDate() + 1) == this.maxDaysOnMonth(this.date.getMonth()+1 , this.date.getFullYear()) ? '01': ((this.date.getDate() + 1) > this.maxDaysOnMonth(this.date.getMonth()+1 , this.date.getFullYear()) ? '02' : '' + (this.date.getDate()+2));
			this.year = this.date.getFullYear();
		}
		console.log(this.month.length);
		if (this.month.length < 2){
			this.month = '0' + this.month;
		}  
		if (this.day.length < 2) {
			this.day = '0' + this.day;
		}
		return [this.year, this.month, this.day].join('-');
    }


    maxDaysOnMonth(month:number, year:number){
        return new Date(year, month, 0).getDate();

    }

	selectClient(){
		$('#cdm-table_filter').fadeOut();
		$('#main-form').fadeIn();
		console.log(this.clientSelect);
		this.clients.forEach(client => {
			if(client.id == Number(this.clientSelect)){
				this.clientName= client.name;
			}
		});
		console.log(this.clientName);
		this.http
				.get(this.url + "application_client?id="+this.clientSelect, {
			headers: new HttpHeaders().set('x-Authorization', '3c7288c1a104694404b07d4e4244a8b8991ca335'),
				})
				.subscribe(
					// Successful responses call the first callback.
					data => {
						if (data != null && data.hasOwnProperty("errorCode")) {
				console.log("error get");
				this.isCampaign=false;
				$('#main-form').fadeOut();
						} else {
							this.campaigns=[];
				this.isCampaign=true;
				this.campaignsData=data;
				this.campaignsData.forEach(campaign => {
				let c={
					"application_ID":campaign.application_ID,
					"name":campaign.name,
					"value": false,
					};
				
					this.campaigns.push(c);
				});
				
				$('#main-form').fadeOut();
						}
					},
					// Errors will call this callback instead:
					err => {
			console.log(err);
			setTimeout(function() {
				$('#main-form').fadeOut();
			}, 50);
					}
				);
	}
	searchRecords(){
		this.isClient=false;
	 	this.isStartDate=false;
		 this.isfinishDate=false;
		 this.isCampaignError=false;
		if(this.startDate ===  "0000-00-00" || this.startDate  === null){
			this.isStartDate=true;
			return this.isStartDate;
		}
		if(this.endDate ===  "0000-00-00" || this.endDate  === null){
			this.isfinishDate=true;
			return this.isfinishDate;
		}
		if(this.clientSelect === 0 || this.clientSelect === null){
			this.isClient=true;
			return this.isClient;
		}
		let notSelect=0;
		for (let index = 0; index < this.campaigns.length; index++) {
			const value = this.campaigns[index].value;
			if(value == false){
				notSelect++;
			}
			
		}
		if(notSelect == this.campaigns.length){
			this.isCampaignError=true;
			return this.isCampaignError;
		}
		this.campaignsData=[];
		this.campaigns.forEach(campaign => {
				if(campaign.value === true){
					this.campaignSelect={"id":campaign.application_ID, "name":campaign.name};
					this.campaignsData.push(this.campaignSelect);
				}
				
			});
		let request= {
			"startDate":this.startDate,
			"endDate":this.endDate,
			"client_id":this.clientSelect,
			"client_name":this.clientName,
			"campaigns":this.campaignsData
		}
		$('#main-form').fadeIn();
		console.log(request);
		
		this.http
			.post(this.localUrl + "registered_user", request, {
				headers: new HttpHeaders().set('x-Authorization', '3c7288c1a104694404b07d4e4244a8b8991ca335'),
			})
			.subscribe(
				// Successful responses call the first callback.
				data => {
					if (data != null && data.hasOwnProperty("errorCode")) {
						console.log(data);
						$('#main-form').fadeOut();
					} else {
						
						
						this.campaignResponse=data['data'].campaigns;
					
						console.log(this.campaignResponse);
						
						this.isData=true;
						
						if (this.campaignResponse) {
							
							this.session.loadScript(this.session.getCmsUrl() + '/assets/Customjs/pages/tables/jquery-datatable.js');
						}
						this.table = $('#cdm-table').DataTable();
						this.table.destroy();
							$('#main-form').fadeOut();
						setTimeout(function() {
							$('#cdm-table_filter').hide();
						}, 50);
					}
				},
				// Errors will call this callback instead:
				err => {
					console.log(err);
					$('#main-form').fadeOut();
				}
			);
			
	}
	
}
